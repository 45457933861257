@import 'mixins';

$desktop-navbar-height: 76px;
$pad-mobile: 0;

.navigation-pad {
  padding-right: $pad-mobile;
  padding-left: $pad-mobile;

  @include breakpoint-md {
    padding-right: 72px;
    padding-left: 72px;
  }
}

.navigation {
  left: 0;
  right: 0;
  display: inline-block;
  padding: 0 $pad-mobile;
  box-shadow: none;
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
  z-index: 1021;

  @include breakpoint-md {
    padding-right: 35px;
    padding-left: 35px;
    height: $desktop-navbar-height;
  }

  @include breakpoint-lg {
    padding-right: 40px;
    padding-left: 40px;
  }

  @include breakpoint-xlg {
    padding-right: 40px;
    padding-left: 40px;
  }

  :global(.nav-link) {
    @include breakpoint-md {
      padding: 0.5rem 0.7rem !important;
    }

    @include breakpoint-xlg {
      padding: 0.5rem 1rem !important;
    }
  }
}

.navCTA {
  padding: 0.5rem 1.5rem;
}

@media (width <= 991px) {
  .navCTA {
    padding: 1rem 0;
  }
}

.fakeshadow {
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 0.04);
}

.fakeshadowMobile {
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 0);
}

.brightOnHover:hover {
  color: #1a2a32;
  opacity: 1;
}

.hamburger {
  border: none;

  &:focus {
    box-shadow: 0 0 0 0.05rem;
  }
}

.navItem {
  font-size: 12px;

  @extend .brightOnHover;

  transition: 0.15s ease-in-out;
}

.shop-desktop-dropdown {
  max-height: 0;
  transition: all 550ms cubic-bezier(0.32, 0.71, 0.23, 0.92);
  box-shadow: 0 9px 10px rgb(0 0 0 / 0.04);
  border-top: 0 solid rgb(26 58 50 / 0);
  margin-top: -1px;

  &.is-open:not(&.disabled) {
    border-top: 1px solid rgb(26 58 50 / 0.2);
    max-height: 400px;
  }

  &:hover {
    @extend .is-open;
  }
}

$animation-duration: 550ms;

.border {
  border-bottom: 1px solid #e5e5e5 !important;

  @include breakpoint-md {
    border-bottom: none !important;
  }
}

.navbar-collapse {
  :global(.nav-item) {
    @extend .border;
  }

  :global(.nav-link) {
    border-radius: 0 !important;
    padding: 1rem 1.5rem;
    opacity: 0.8;
    color: #1a3a32;
  }
}

.border-except-last {
  &:not(:last-child) {
    @extend .border;
  }
}

.shop-mobile-dropdown {
  :global(.dropdown-menu) {
    margin-top: -1px;
    display: block;
    background: rgb(26 58 50 / 0.05);
    padding: 0;
    transition: all $animation-duration cubic-bezier(0.32, 0.71, 0.23, 0.92);
    max-height: 0;
    overflow: hidden;
    border: none;
  }

  :global(.dropdown-menu.show) {
    max-height: 400px;
    overflow: auto;
  }

  :global(.dropdown-menu.show)::-webkit-scrollbar {
    width: 0 !important;
  }

  :global(.dropdown-toggle) {
    @extend .border;

    &::after {
      content: none;
    }
  }
}

.nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @include breakpoint-md {
    display: block;
  }
}

.dropdown-carret {
  transition: transform 250ms;

  &.rotate {
    transform: rotate(-180deg);
  }
}

.bannerStrip {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: -199px;
    width: 100%;
    height: 200px;
    background: var(--color-primary);
    left: 0;
    z-index: -1;
  }
}

.sellerStrip {
  position: relative;
  transition: all 500ms;

  &::before {
    position: absolute;
    content: '';
    top: -199px;
    width: 100%;
    height: 200px;
    left: 0;
    z-index: -1;
  }

  &:hover {
    background-color: var(--color-success) !important;
    color: var(--color-white) !important;

    span {
      color: var(--color-black) !important;
    }
  }
}

.darkNav {
  color: #fffcf9 !important;
  opacity: 0.6;

  &:hover {
    color: #fffcf9 !important;
    opacity: 1 !important;
  }
}

.borderDark {
  border-left: 1px solid rgb(255 252 249 / 0.4) !important;
}

.authButtonDark {
  color: '#fffcf9';
  background-color: rgb(255 252 249 / 0.16);
  border-color: transparent;
}

.bgDarkBlack {
  background: rgb(27 28 29 / 0.8) !important;
  backdrop-filter: blur(27px);
  -webkit-backdrop-filter: blur(27px);
}

.dropdownDark {
  a {
    color: #fffcf9 !important;

    &:hover {
      color: #fffcf9 !important;
      opacity: 1;
    }
  }

  > div {
    background: rgb(37 39 41 / 0.8);
    backdrop-filter: blur(27px);

    a {
      color: #fffcf9 !important;

      &:hover {
        color: rgb(37 39 41 / 0.8) !important;
        opacity: 1;
        background: #fffcf9;
      }
    }
  }

  hr {
    background-color: #fffcf9;
  }
}

.shopAll {
  color: var(--color-success);

  :hover,
  :hover a {
    color: var(--color-successDark);
  }
}

.shopAllDark {
  color: var(--color-turquoise-90);

  :hover,
  :hover a {
    color: var(--color-turquoise);
  }
}

.navbarDropdownItem {
  opacity: 0.85;

  &:hover {
    opacity: 1;
  }
}
